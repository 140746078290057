import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Footer = (): React.ReactNode => {
  return (
    <footer className="bg-white pt-3 pb-5">
      <div className="container">
        <div className="row mx-auto mt-4 mx-md-0">
          <div className="col-11 col-md-4">
            <h4>Allen Institute for Immunology</h4>
            <span>
              {/* eslint-disable-next-line max-len */}
              The Allen Institute for Immunology, a division of the Allen Institute, is working to understand the dynamic balancing act of the human immune system, how it senses friend from foe and what goes wrong when we’re ill. Our studies aim to translate deep knowledge of the immune system in health and disease to improve the diagnosis, treatment and prevention of immune-related disorders.
            </span>
            <div className="aifi-logo_wrapper mt-4">
              <a href="https://alleninstitute.org/what-we-do/immunology/">
                <img
                  style={{ width: 170 }}
                  src="https://storage.googleapis.com/aifi-static-assets/AI_Immunology_Logo_1C_CMYK.svg"
                  alt="allen institute for immunology logo"
                />
              </a>
            </div>
          </div>
          <div className="connect col-md-4 offset-md-1">
            <h4>Connect</h4>
            <Link to="/contact">
              <button type="button" className="hise-btn text-nowrap">
                Send us a message
              </button>
            </Link>
            <hr />
            <a
              href="https://www.facebook.com/AllenInstitute/"
              className="text-decoration-none me-2"
            >
              <FontAwesomeIcon icon={faFacebook} className="fa-lg" />
            </a>
            <a
              href="https://twitter.com/AllenInstitute"
              className="text-decoration-none me-2"
            >
              <FontAwesomeIcon icon={faTwitter} className="fa-lg" />
            </a>
            <a
              href="https://instagram.com/alleninstitute/"
              className="text-decoration-none me-2"
            >
              <FontAwesomeIcon icon={faInstagram} className="fa-lg" />
            </a>
            <a
              href="https://www.linkedin.com/company/allen-institute-for-immunology"
              className="text-decoration-none me-2"
            >
              <FontAwesomeIcon icon={faLinkedin} className="fa-lg" />
            </a>
            <a
              href="https://www.youtube.com/AllenInstitute"
              className="text-decoration-none"
            >
              <FontAwesomeIcon icon={faYoutube} className="fa-lg" />
            </a>
            <hr />
            <a
              href="https://secure2.convio.net/allins/site/SPageServer?pagename=sign_up_news"
              className="text-decoration-none"
            >
              Subscribe to our newsletter
            </a>
          </div>
        </div>

        <div className="row mb-4 mx-auto my-4 mx-md-0">
          <div className="col">
            <a
              href="https://alleninstitute.org/legal/terms-use/"
              className="text-decoration-none"
            >
              Terms of use
            </a>
            <span className="px-2">|</span>
            <a
              href="https://alleninstitute.org/legal/privacy-policy/"
              className="text-decoration-none"
            >
              Privacy policy
            </a>
            <span className="px-2">|</span>
            <a
              href="https://alleninstitute.org/legal/citation-policy/"
              className="text-decoration-none"
            >
              Citation policy
            </a>
            <span className="px-2">|</span>
            <button
              type="button"
              id="ot-sdk-btn"
              className="ot-sdk-show-settings bg-transparent"
              // Override onetrust style
              style={{
                fontWeight: '300',
                padding: 0,
                border: 0,
              }}
            >
              Cookie Settings
            </button>
          </div>
        </div>

        <div className="row mx-auto mt-4 mx-md-0">
          <div className="col">
            Copyright © 2022-2024. Allen Institute for Immunology. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
